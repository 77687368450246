import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

export const CaseInfoContainer = ({ name, phone, location }) => {
  return (
    <Card>
      <Typography variant="h2">Saksinfo:</Typography>
      <CardContent>
        <Typography>Navn på kontaktperson: {name}</Typography>
        <Typography>Telefonnummer til kontaktperson: {phone}</Typography>
        <Typography>Bilens lokasjon: {location}</Typography>
      </CardContent>
    </Card>
  );
};

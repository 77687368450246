import { Box, Card, Typography } from '@mui/material';
import CarContainer from '../../../Container/CarContainer';

const CarContainerCard = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography variant="body2">No car data available.</Typography>;
  }
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: 2,
        p: 1,
      }}
      component={Card}
    >
      <CarContainer data={data} />
    </Box>
  );
};

export default CarContainerCard;

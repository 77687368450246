export const mapSearchResult = (result, lat = null, lon = null) => {
  const address = result.address || {};

  return {
    key: result.id || null,
    type: result.poi ? 'POI' : 'Address',
    name: result.poi?.name || address.freeformAddress || 'Coordinates',
    label: result.poi?.name || address.freeformAddress || 'Search',
    address: address.freeformAddress || '',
    position: {
      lat: lat || result.position?.lat || null,
      lon: lon || result.position?.lon || null,
    },
    businessName: result.poi?.name || 'N/A',
    streetNumber: address.streetNumber || '',
    street: address.street || '',
    countryCode: address.countryCode || '',
    countryCodeISO: address.countryCodeISO3 || '',
    city: address.countrySubdivision || '',
    postalCode: address.postalCode || '',
    country: address.country || '',
    freeformAddress: address.freeformAddress || '',
    positionString: `${lat || result.position?.lat || ''},${lon || result.position?.lon || ''}`,
  };
};

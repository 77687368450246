import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './api/auth/authConfig';
import AuthProvider from './providers/AuthProvider';

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <App msalInstance={msalInstance} />
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>
);

// Export the MSAL instance for use in axios and other parts of the app
export default msalInstance;

import React from 'react';
import PageLayout from './layout/PageLayout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PageMissing, ReviewPage, CasePage } from './pages/index';
import { ThemeProvider } from '@emotion/react';
import { ColorModeContext, useMode } from './Styles/Theme';
import { SidebarProvider } from './Context/SidebarContext';
import { LayoutProvider } from './Context/LayoutContext';

const App = () => {
  const [theme, colorMode] = useMode();
  return (
    <LayoutProvider>
      <SidebarProvider>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <Router>
              <Routes>
                <Route path="/" element={<PageLayout />}>
                  <Route path="/case" element={<CasePage />} />
                  <Route path="/review" element={<ReviewPage />} />
                  <Route path="/review/:id" element={<ReviewPage />} />
                  <Route path="*" element={<PageMissing />} />
                </Route>
              </Routes>
            </Router>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </SidebarProvider>
    </LayoutProvider>
  );
};

export default App;

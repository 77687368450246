import { useCallback } from 'react';
import useRegSearchStore from '../../hooks/store/useRegSearchStore';
import { useAxiosInstance } from '../../api/axiosInstanceTwo';
import { useFormikContext } from 'formik';

export const useRegistrationSearch = () => {
  const { setFieldValue } = useFormikContext();
  const { setPcdData, setCaseData, setRsaData, setLoading, setError } = useRegSearchStore();
  const axiosInstance = useAxiosInstance();

  const pcdUrl = `${process.env.REACT_APP_PCD_API_BASE_URL}/pcd/search`;
  const countryCode = useRegSearchStore((state) => state.countryCode);

  console.log(countryCode);

  const getCountryFromCode = (code) => {
    switch (code) {
      case 'SE':
        return 'Sweden';
      case 'FI':
        return 'Finland';
      case 'NO':
        return 'Norway';
      default:
        return 'Norway';
    }
  };

  const fetchRegData = useCallback(
    async (registrationNumber) => {
      console.log('registrationNumber', registrationNumber);
      setLoading(true);
      setError(null);

      try {
        const pcdResponse = await axiosInstance.post(pcdUrl, {
          registrationNumber: registrationNumber.toUpperCase(),
          countryCode: getCountryFromCode(countryCode),
          headers: { 'Content-Type': 'application/json' },
        });

        console.log('pcdResponse', pcdResponse.data);

        if (pcdResponse.status === 200) {
          const data = pcdResponse.data;
          setPcdData(data);

          // eslint-disable-next-line
          const newFormikValues = {
            registrationNumberCountry: getCountryFromCode(countryCode) || '',
            address: data.owner?.[0]?.address || '',
            city: data.owner?.[0]?.city || '',
            zip: data.owner?.[0]?.zip || '',
            bodyType: data.vehicleData?.bodyType || '',
            colour: data.vehicleData?.colour || '',
            dateOfFirstRegistration: data.vehicleData?.dateOfFirstRegistration ? new Date(data.vehicleData.dateOfFirstRegistration).toISOString().split('T')[0] : '',
            fuelType: data.vehicleData?.technicalData?.fuelTypes?.[0] || '',
            insuranceCompany: data.insurance?.name || '',
            lastName: data.owner?.[0]?.lastName || '',
            length: String(data.vehicleData?.length || ''),
            make: data.vehicleData?.make || '',
            model: data.vehicleData?.model || '',
            vin: data.vehicleData?.vin || '',
            weight: String(data.vehicleData?.technicalData?.weight || ''),
            width: String(data.vehicleData?.width || ''),
            year: String(data.vehicleData?.year || ''),
            numberOfAxlesInOperation: data.vehicleData?.technicalData?.totalNumberOfAxles || '',
            totalNumberOfAxles: data.vehicleData?.technicalData?.totalNumberOfAxles || '',
            registrationNumber: data.registrationNumber || '',
            organizationNumber: data.owner?.[0]?.idNumber || '',
            automaticTransmission: data.vehicleData?.technicalData?.automaticTransmission !== undefined ? data.vehicleData.technicalData.automaticTransmission : null,
          };

          setFieldValue('pcdData', newFormikValues);
        } else {
          setError({ status: 404, message: 'No vehicle data found' });
        }

        const rsaResponse = await axiosInstance.get(`${process.env.REACT_APP_RSA_API_BASE_URL}?registrationNumber=${registrationNumber}`);
        if (rsaResponse) {
          const rsaCaseData = rsaResponse.data;
          setRsaData(rsaCaseData.cases || rsaCaseData);
          setCaseData(rsaCaseData.cases || rsaCaseData);
        }
      } catch (error) {
        setError({ status: error.response?.status || 500, message: error.message });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, countryCode, axiosInstance, pcdUrl, setPcdData, setFieldValue, setRsaData, setCaseData]
  );

  return { fetchRegData };
};

import React from 'react';
import { Box, FormControl, TextField, Tooltip, Typography } from '@mui/material';

const RightsidebarContent = () => {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Tooltip title="NB! The text will be deleted when this is closed"></Tooltip>
      </Box>
      <Box sx={{ overflow: 'none' }}>
        <Typography variant="caption" color="error">
          *NB! The text will be deleted when this is close
        </Typography>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Case notes"
            name="caseComment"
            multiline
            rows={20}
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <Typography variant="caption" color="error">
          *NB! The text will be deleted when this is close
        </Typography>
      </Box>
    </>
  );
};

export default RightsidebarContent;

import React, { useEffect } from 'react';
import './CarLoading.css';

const CarLoading = ({ state }) => {
  useEffect(() => {
    function changeAnimation(state) {
      const movingCar = document.getElementById('moving-car');
      if (movingCar) {
        switch (state) {
          case 'error':
            movingCar.setAttribute('className', 'moving-car moving-car--error');
            break;
          case 'complete':
            movingCar.setAttribute('className', 'moving-car moving-car--complete');
            break;
          default:
            movingCar.setAttribute('className', 'moving-car');
        }
      }
    }

    changeAnimation(state);
  }, [state]);

  return (
<div className="container">
	<svg id="moving-car" className="moving-car" width="265" height="161" viewBox="0 0 265 161" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect className="moving-car__check-bottom" x="78" y="103.574" width="30" height="65" transform="rotate(-60 78 103.574)" fill="#00B494"/>
		<rect className="moving-car__check-side" x="158.792" y="33" width="30" height="101" transform="rotate(210 161 88)" fill="#00B494"/>
		<g className="moving-car__road">
			<rect x="0" y="155.738" width="35.8197" height="4.67213" rx="2.33607" fill="white"/>
			<rect x="0" y="155.738" width="35.8197" height="4.67213" rx="2.33607" fill="#2B4051" fill-opacity="0.9"/>
			<rect x="40" y="155.738" width="28.0328" height="4.67213" rx="2.33607" fill="white"/>
			<rect x="40" y="155.738" width="28.0328" height="4.67213" rx="2.33607" fill="#2B4051" fill-opacity="0.9"/>
			<rect x="72" y="155.738" width="119.918" height="4.67213" rx="2.33607" fill="white"/>
			<rect x="72" y="155.738" width="119.918" height="4.67213" rx="2.33607" fill="#2B4051" fill-opacity="0.9"/>
			<rect x="196" y="155.738" width="66" height="4.67213" rx="2.33607" fill="white"/>
			<rect x="196" y="155.738" width="66" height="4.67213" rx="2.33607" fill="#2B4051" fill-opacity="0.9"/>
			<rect x="265" y="155.738" width="35.8197" height="4.67213" rx="2.33607" fill="white"/>
			<rect x="265" y="155.738" width="35.8197" height="4.67213" rx="2.33607" fill="#2B4051" fill-opacity="0.9"/>
			<rect x="305" y="155.738" width="28.0328" height="4.67213" rx="2.33607" fill="white"/>
			<rect x="305" y="155.738" width="28.0328" height="4.67213" rx="2.33607" fill="#2B4051" fill-opacity="0.9"/>
			<rect x="337" y="155.738" width="119.918" height="4.67213" rx="2.33607" fill="white"/>
			<rect x="337" y="155.738" width="119.918" height="4.67213" rx="2.33607" fill="#2B4051" fill-opacity="0.9"/>
			<rect x="461" y="155.738" width="66" height="4.67213" rx="2.33607" fill="white"/>
			<rect x="461" y="155.738" width="66" height="4.67213" rx="2.33607" fill="#2B4051" fill-opacity="0.9"/>
		</g>
		<g className="moving-car__background" opacity="0.15">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M203.484 158C218.35 141.233 227.377 119.171 227.377 95C227.377 42.5329 184.844 0 132.377 0C79.91 0 37.377 42.5329 37.377 95C37.377 119.171 46.4038 141.233 61.27 158H203.484Z" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M203.484 158C218.35 141.233 227.377 119.171 227.377 95C227.377 42.5329 184.844 0 132.377 0C79.91 0 37.377 42.5329 37.377 95C37.377 119.171 46.4038 141.233 61.27 158H203.484Z" fill="#2A3A3A" fill-opacity="0.8"/>
		</g>
		<rect className="moving-car__movement-stripe" x="64.0001" y="112" width="34" height="4" rx="2" fill="white"/>
		<rect className="moving-car__movement-stripe" x="40.0001" y="119" width="34" height="4" rx="2" fill="white"/>
		<rect className="moving-car__movement-stripe" x="57.0001" y="126" width="34" height="4" rx="2" fill="white"/>
		<g className="moving-car__car-body">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M223.882 109.377C223.882 107.168 222.092 105.377 219.882 105.377H96.9999C94.7908 105.377 92.9999 107.168 92.9999 109.377V131.525C92.9999 134.286 95.2385 136.525 97.9999 136.525H101.103C102.547 129.416 108.835 124.066 116.373 124.066C123.911 124.066 130.199 129.416 131.642 136.525H182.126C183.569 129.416 189.857 124.066 197.395 124.066C204.933 124.066 211.221 129.416 212.665 136.525H218.882C221.644 136.525 223.882 134.286 223.882 131.525V109.377Z" fill="#ff182a"/>
			<ellipse cx="3.11625" cy="3.11475" rx="3.11625" ry="3.11475" transform="matrix(-1 0 0 1 227 119.393)" fill="#FFAB53"/>
			<path d="M174.989 73C182.634 73 192.073 97.6995 195.838 110.049H97.6758C97.6758 81.3172 108.969 73 115.05 73H174.989Z" fill="#ff182a"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M145.056 75.7455H171.969C179.007 75.7455 187.698 95.871 191.163 105.934H145.056V75.7455ZM143.211 105.934H100.792C100.792 82.5224 111.189 75.7455 116.787 75.7455H143.211V105.934Z" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M145.056 75.7455H171.969C179.007 75.7455 187.698 95.871 191.163 105.934H145.056V75.7455ZM143.211 105.934H100.792C100.792 82.5224 111.189 75.7455 116.787 75.7455H143.211V105.934Z" fill="#2B4051" fill-opacity="0.9"/>
			<rect width="4.67437" height="1.37219" transform="matrix(-1 0 0 1 152.21 108.677)" fill="black"/>
			<rect width="4.67437" height="1.37219" transform="matrix(-1 0 0 1 152.21 108.677)" fill="#EFF0F2" fill-opacity="0.9"/>
			<rect width="4.67437" height="1.37219" transform="matrix(-1 0 0 1 110.141 108.677)" fill="black"/>
			<rect width="4.67437" height="1.37219" transform="matrix(-1 0 0 1 110.141 108.677)" fill="#EFF0F2" fill-opacity="0.9"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M171.969 75.7461H145.056V99.072H188.57C184.349 88.7446 177.625 75.7461 171.969 75.7461ZM101.121 99.072C102.931 81.1693 111.779 75.7461 116.787 75.7461H143.211V99.072H101.121Z" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M171.969 75.7461H145.056V99.072H188.57C184.349 88.7446 177.625 75.7461 171.969 75.7461ZM101.121 99.072C102.931 81.1693 111.779 75.7461 116.787 75.7461H143.211V99.072H101.121Z" fill="#2B4051" fill-opacity="0.8"/>
			<g className="moving-car__breakdown">
				<rect className="moving-car__wheel-deflate" x="97" y="142" width="37" height="9" rx="4.5" fill="#000"/>
				<rect className="moving-car__wheel-deflate" x="179" y="142" width="37" height="9" rx="4.5" fill="#000"/>
				<path className="moving-car__hood" d="M223.682 112.296C222.21 108.607 218.605 106 214.391 106L194.86 106C191.293 106 188.162 107.868 186.392 110.678C187.933 109.709 189.756 109.148 191.711 109.148H211.242C214.113 109.148 216.701 110.358 218.525 112.296H223.682Z" fill="#ff182a"/>
				<ellipse className="moving-car__cloud" cx="3.5" cy="2" rx="3.5" ry="2" transform="matrix(-1 0 0 1 214 98)" fill="white"/>
				<ellipse className="moving-car__cloud" cx="5.5" cy="3" rx="5.5" ry="3" transform="matrix(-1 0 0 1 212 86)" fill="white"/>
				<ellipse className="moving-car__cloud" cx="9" cy="5" rx="9" ry="5" transform="matrix(-1 0 0 1 221 76)" fill="white"/>
				<ellipse className="moving-car__cloud" cx="11" cy="6" rx="11" ry="6" transform="matrix(-1 0 0 1 214 68)" fill="white"/>
				<ellipse className="moving-car__cloud" cx="3.5" cy="2" rx="3.5" ry="2" transform="matrix(-1 0 0 1 214 98)" fill="white"/>
				<ellipse className="moving-car__cloud" cx="5.5" cy="3" rx="5.5" ry="3" transform="matrix(-1 0 0 1 212 86)" fill="white"/>
				<ellipse className="moving-car__cloud" cx="9" cy="5" rx="9" ry="5" transform="matrix(-1 0 0 1 221 76)" fill="white"/>
				<ellipse className="moving-car__cloud" cx="11" cy="6" rx="11" ry="6" transform="matrix(-1 0 0 1 214 68)" fill="white"/>
			</g>
			<g className="moving-car__car-wheel">
				<path d="M183.372 139.639C183.372 147.38 189.651 153.656 197.395 153.656C205.14 153.656 211.419 147.38 211.419 139.639C211.419 131.898 205.14 125.623 197.395 125.623C189.651 125.623 183.372 131.898 183.372 139.639Z" fill="#000"/>
				<ellipse cx="6.2325" cy="6.22951" rx="6.2325" ry="6.22951" transform="matrix(-1 0 0 1 203.628 133.41)" fill="white"/>
				<ellipse cx="6.2325" cy="6.22951" rx="6.2325" ry="6.22951" transform="matrix(-1 0 0 1 203.628 133.41)" fill="#2B4051" fill-opacity="0.8"/>
				<path d="M101.907 139.639C101.907 147.38 108.186 153.656 115.93 153.656C123.675 153.656 129.954 147.38 129.954 139.639C129.954 131.898 123.675 125.623 115.93 125.623C108.186 125.623 101.907 131.898 101.907 139.639Z" fill="#000"/>
				<ellipse cx="6.2325" cy="6.22951" rx="6.2325" ry="6.22951" transform="matrix(-1 0 0 1 122.606 133.41)" fill="white"/>
				<ellipse cx="6.2325" cy="6.22951" rx="6.2325" ry="6.22951" transform="matrix(-1 0 0 1 122.606 133.41)" fill="#2B4051" fill-opacity="0.8"/>
			</g>
		</g>
	</svg>
</div>
  );
};

export default CarLoading;

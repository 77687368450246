import useRsaDataStore from '../hooks/store/useRsaDataStore';
import axiosInstance from './axiosInstance';
import { xmlConverter } from './xmlParser';

const urlRsa = process.env.REACT_APP_RSA_API_BASE_URL;

// RSA API

export const getRsaData = async (searchValue) => {
  try {
    const response = await axiosInstance.get(
      urlRsa + `?registrationNumber=${encodeURIComponent(searchValue)}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    const rsaCarDetails = JSON.stringify(response.data);
    localStorage.setItem('RSA_CAR_DETAILS', JSON.stringify(response.data));
    return rsaCarDetails;
  } catch (error) {
    console.error('getRsaData call failed', error);
    throw error;
  }
};

export const getRsaCaseData = async (instance, searchValue) => {
  console.log('searchValue:', searchValue);
  const setRsaCaseDetails = useRsaDataStore.getState().setRsaCaseDetails;

  try {
    const response = await instance.get(urlRsa + `?caseId=${encodeURIComponent(searchValue)}`);

    const rsaCaseDetails = response.data.cases[0];
    console.log('rsaCaseDetails', rsaCaseDetails);

    // Use the store to set the case details
    setRsaCaseDetails(rsaCaseDetails);

    return rsaCaseDetails;
  } catch (error) {
    console.error('API call failed', error);
    alert('getRsaCaseData API failed to fetch');
    throw error;
  }
};

export const postRsaCase = async (body) => {
  console.log('Request body:', body);

  try {
    const request = await axiosInstance.post(urlRsa, body, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    });
    console.log('Request:', request);
    localStorage.setItem('response', JSON.stringify(request.data));
    return request.data;
  } catch (error) {
    console.error('API call failed:', error.message);
    throw error; // Uncomment if you need to propagate the error
  }
};

// PCD

const urlPcd = process.env.REACT_APP_PCD_API_BASE_URL;

export const getPcdData = async (searchValue, countryCode) => {
  try {
    const xmlData = `<?xml version="1.0" encoding="UTF-8"?>
<Vehicle_Search_Parameters>
<Country_Code>${countryCode}</Country_Code>
    <Registration_Number>${searchValue}</Registration_Number>
</Vehicle_Search_Parameters>`;

    const response = await axiosInstance.post(urlPcd, xmlData, {
      headers: {
        'Content-Type': 'application/xml', // Set the Content-Type to application/xml
      },
    });

    const pcdData = xmlConverter(response.data);
    console.log('pcdData', pcdData);

    // Return both data and status code
    return {
      status: response.status,
      data: pcdData,
    };
  } catch (error) {
    // Check if the error has a response (which includes the status)
    if (error.response) {
      console.error('API call failed:', error.response.status, error.message);
      return {
        status: error.response.status,
        message: error.message,
      };
    } else {
      // Handle generic network or other errors
      console.error('Network or unexpected error:', error.message);
      return {
        status: null, // No status available (network failure or unexpected issue)
        message: error.message,
      };
    }
  }
};

export const priorityEN = [
  { value: '1', label: '1. Immidiate Priority', tooltip: 'Only use for the police.' },
  { value: '2', label: '2. High Priority', tooltip: 'Cause of trafic blockable, dager of life / health, highway' },
  { value: '3', label: '3. Normal Priority', tooltip: 'Standard priority.' },
  { value: '4', label: '4. Low Priority', tooltip: "When the client is OK waiting for some hours, like :I'm just at home doing nothing to day, come whenever. Should be done within the day" },
  { value: '5', label: '5. Pre ordered', tooltip: 'Is used whn the assistance is planned for day(s) ahead, like tomorrow, next week etc.' },
];

export const priorityNO = [
  { value: '1', label: '1. Umiddelbar Prioritet', tooltip: 'Brukes kun for politiet.' },
  { value: '2', label: '2. Høy Prioritet', tooltip: 'På grunn av trafikkblokade, fare for liv/helse, motorvei' },
  { value: '3', label: '3. Normal Prioritet', tooltip: 'Standard prioritet.' },
  { value: '4', label: '4. Lav Prioritet', tooltip: 'Når klienten er OK med å vente i noen timer, som: "Jeg er bare hjemme og gjør ingenting i dag, kom når som helst." Bør gjøres innen dagen.' },
  { value: '5', label: '5. Forhåndsbestilt', tooltip: 'Brukes når hjelpen er planlagt for dag(er) fremover, som i morgen, neste uke osv.' },
];

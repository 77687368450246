import axios from 'axios';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { apiScopes } from './auth/authConfig';
import msalInstance from '../index'; // Import the MSAL instance

const axiosInstance = axios.create({
  baseURL: '', // Replace with your API base URL
  headers: { },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      // Determine scopes based on the request URL or logic
      const scopes = config.url.includes('/pcd') ? apiScopes.pcd : apiScopes.default;

      // Get the account information
      const account = msalInstance.getAllAccounts()[0];

      // If there are no accounts, redirect to login
      if (!account) {
        throw new InteractionRequiredAuthError(); // Forces redirect to login
      }

      // Acquire token silently using the MSAL instance
      const response = await msalInstance.acquireTokenSilent({
        scopes,
        account,
      });

      // Attach the token to the request headers
      config.headers.Authorization = `Bearer ${response.accessToken}`;
    } catch (error) {
      console.error('Token acquisition failed', error);

      if (error instanceof InteractionRequiredAuthError) {
        // Redirect the user to login if interaction is required
        msalInstance.loginRedirect(); // Use MSAL's loginRedirect function
      } else {
        // Optionally, handle other errors
        throw error;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Optionally handle response errors here
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors, e.g., show a notification or redirect
      console.error('Unauthorized access - Redirecting to login');
      msalInstance.loginRedirect(); // Redirect to login if token is invalid
    } else {
      console.error('axiosInstance request error', error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

import React, { useEffect, useState } from 'react';

import { FormControl, TextField, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';

export const ContactName = () => {
  const { values, errors, handleChange, handleBlur } = useFormikContext();
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    if (errors.onSiteContact?.contactName && errors.onSiteContact?.contactName) {
      setInputError(errors.onSiteContact.contactName);
    } else {
      setInputError('');
    }
  }, [errors.onSiteContact?.contactName]);

  return (
    <Tooltip title="Hvem er det som ringer? " placement="top" arrow>
      <FormControl fullWidth variant="outlined" error={!!inputError || (errors.onSiteContact?.contactName && errors.onSiteContact?.contactName)}>
        <TextField
          id="onSiteContact.contactName"
          name="onSiteContact.contactName"
          label="Kontakt navn"
          variant="outlined"
          value={values.onSiteContact?.contactName || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.onSiteContact?.contactName && errors.onSiteContact?.contactName}
          error={!!inputError || (errors.onSiteContact?.contactName && errors.onSiteContact?.contactName)}
        />
      </FormControl>
    </Tooltip>
  );
};

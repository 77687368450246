import { Autocomplete, Divider, FormControl, TextField, Tooltip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { categoryTranslations, coverCauses } from '../../../../options/CoverCasue';

export const CoverCauseSelector = () => {
  const { values, errors, setFieldValue } = useFormikContext();

  const lvl4CoverCauses = coverCauses.filter((option) => option.lvl === 4); // eslint-disable-line
  const lvl3CoverCauses = coverCauses.filter((option) => option.lvl === 3); // eslint-disable-line
  const unfiltered = coverCauses; // eslint-disable-line

  return (
    <Tooltip title="Velg hva som har skjedd med kunden" placement="top">
      <FormControl fullWidth variant="outlined" error={Boolean(errors.incident?.incidentCause && errors.incident?.incidentCause)}>
        <Autocomplete
          options={lvl4CoverCauses}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.label}
          value={lvl4CoverCauses.find((option) => option.value === values.incident?.incidentCause) || null}
          onChange={(event, newValue) => {
            console.log('Selected cover cause:', newValue.value);

            setFieldValue('incident.incidentCause', newValue ? newValue.value : '');
          }}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Velg en dekning årsak"
              variant="outlined"
              fullWidth
              error={Boolean(errors.incident?.incidentCause && errors.incident?.incidentCause)}
              helperText={errors.incident?.incidentCause && errors.incident?.incidentCause}
            />
          )}
          renderGroup={(params) => (
            <>
              <Typography variant="h3" fontWeight={'bold'} marginTop={2}>
                {categoryTranslations[params.group] || params.group}
              </Typography>
              <Divider />
              {params.children}
            </>
          )}
          key={(option) => option.id}
        />
      </FormControl>
    </Tooltip>
  );
};

import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { formatAddress } from '../../utils/format/formatAddress';

const OwnerInfoDialog = ({ data, open, handleClose }) => {
  const ownerData = data.owner?.[0] || {};
  const vehicleData = data.vehicleData || {};
  const technicalData = vehicleData.technicalData || {};

  const isCompany = ownerData.idNumber && ownerData.idNumber.toString().length >= 9;

  const ownerRows = isCompany
    ? [
        { property: 'Firmanavn', value: ownerData.companyName || ownerData.lastName },
        { property: 'Organisasjonsnummer', value: ownerData.idNumber },
        { property: 'Adresse', value: formatAddress(ownerData.address) },
        { property: 'By', value: ownerData.city },
        { property: 'Postnummer', value: ownerData.zip },
      ]
    : [
        { property: 'Fornavn', value: ownerData.firstName },
        { property: 'Etternavn', value: ownerData.lastName },
        { property: 'Adresse', value: formatAddress(ownerData.address) },
        { property: 'By', value: ownerData.city },
        { property: 'Postnummer', value: ownerData.zip },
      ];

  const carRows = [
    { property: 'Registreringsnummer', value: data.registrationNumber },
    { property: 'Merke', value: vehicleData.make },
    { property: 'Modell', value: vehicleData.model },
    { property: 'Dato for første registrering', value: vehicleData.dateOfFirstRegistration ? new Date(vehicleData.dateOfFirstRegistration).toLocaleDateString() : '' },
    { property: 'Vin', value: vehicleData.vin },
    { property: 'Forsikringsselskap', value: data.insurance?.name },
    { property: 'Karosseritype', value: vehicleData.bodyType },
    { property: 'Drivstofftype', value: technicalData.fuelTypes?.[0] },
    { property: 'Vekt', value: technicalData.weight },
    { property: 'Bredde', value: vehicleData.width },
    { property: 'Lengde', value: vehicleData.length },
    { property: 'Farge', value: vehicleData.colour },
    { property: 'Totalt antall aksler', value: technicalData.totalNumberOfAxles },
    { property: 'Automatgir', value: technicalData.automaticTransmission?.toString() },
  ];

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
      <DialogTitle id="alert-dialog-title">Eierinformasjon</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 500 }} size="small" aria-label="car details">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <strong>Bilinformasjon:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carRows.map((row) => (
                  <TableRow key={row.property} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.property}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table sx={{ minWidth: 500 }} size="small" aria-label="owner details">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <strong>Eierinformasjon:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ownerRows.map((row) => (
                  <TableRow key={row.property} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.property}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OwnerInfoDialog;

import { useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import CaseStatusAlert from '../CaseStatus/CaseStatusAlert';
import ViewMore from '../../../Button/ViewMore';
import NoCaseSuccess from '../CaseStatus/NoCaseSuccsess';

const CaseList = ({ caseData }) => {
  const [displayedCases, setDisplayedCases] = useState(3);

  if (!caseData || caseData.length === 0) {
    return <NoCaseSuccess />;
  }

  const handleLoadMore = () => {
    setDisplayedCases((prev) => prev + 3);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: 2,
        p: 1,
      }}
      component={Card}
    >
      <Typography variant="h6">Saker fra de siste 7 dagene:</Typography>
      {caseData.slice(0, displayedCases).map((caseItem) => (
        <CaseStatusAlert key={caseItem.caseId} data={caseItem} />
      ))}
      {displayedCases < caseData.length && <ViewMore handleOnClick={handleLoadMore} />}
    </Box>
  );
};

export default CaseList;

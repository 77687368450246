import { searchCoordinates } from '../../../api/azureMapsService';

const handleSelect = async (event, selectedOption, results, inputValue, setSearchResult) => {
  console.log('handleSelect triggered with selectedOption:', selectedOption);
  console.log('Available results:', results);

  if (selectedOption) {
    const normalizedOption = selectedOption.trim();
    const latLonPattern = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;

    if (latLonPattern.test(normalizedOption)) {
      const [lat, lon] = normalizedOption.split(',').map(Number);
      const result = await searchCoordinates(lat, lon);

      console.log('Reverse geocode result:', result);
      if (result) {
        setSearchResult({
          ...result,
          lat: result.position.lat,
          lon: result.position.lon,
        });
        console.log('Coordinates searched and set in store:', result);
      } else {
        console.warn('No valid result found for coordinates:', selectedOption);
      }
    } else {
      const selectedResult = results.find((result) => result.label.toLowerCase().includes(normalizedOption.toLowerCase()));
      if (selectedResult) {
        setSearchResult({
          ...selectedResult,
          lat: selectedResult.position.lat,
          lon: selectedResult.position.lon,
        });
        console.log('Selected Result Set in Store:', selectedResult);
      } else {
        console.warn('No matching result found for:', selectedOption);
      }
    }
  }
};

export default handleSelect;

import React from 'react';
import { Box } from '@mui/material';
import { CaseDetailsContainer } from '../../components/Container/CaseDetailsContainer';
import { CaseInfoContainer } from '../../components/Container/CaseInfoContainer';

export const ReviewLayout = ({ caseData }) => {
  if (!caseData) return;

  console.log(caseData);

  return (
    <Box>
      <CaseInfoContainer name={caseData.contact?.name} phone={caseData.contact?.phoneNumber} location={caseData.location?.address} />
      <CaseDetailsContainer data={caseData} />
    </Box>
  );
};

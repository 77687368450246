import React from 'react';

import { useFormikContext } from 'formik';

import { TextField } from '@mui/material';

export const LocationDescription = () => {
  const { values, handleChange, handleBlur } = useFormikContext();
  return <TextField name="location.locationMessage" label="Stedsbeskrivelse" variant="outlined" fullWidth value={values.location.locationMessage || ''} onChange={handleChange} onBlur={handleBlur} />;
};

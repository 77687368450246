import React from 'react';
import { Box, Alert } from '@mui/material';
import CaseList from './CaseList';
import CarContainerCard from './CarContainerCard';
import LoadingCar from '../../../Loading/LoadingCar';
import useRegSearchStore from '../../../../hooks/store/useRegSearchStore';

const SearchResultsDisplay = () => {
  const { loading, pcdData, caseData, hasSearched, error } = useRegSearchStore(
    (state) => ({
      loading: state.loading,
      pcdData: state.pcdData,
      caseData: state.caseData,
      error: state.error,
      hasSearched: state.hasSearched,
    })
  );

  if (!hasSearched) {
    return null;
  }

  if (error) {
    return (
      <Alert severity="error" variant="filled">
        {error.message}
      </Alert>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {loading ? (
        <LoadingCar />
      ) : (
        <>
          <CarContainerCard data={pcdData} />
          <CaseList caseData={caseData} />
        </>
      )}
    </Box>
  );
};

export default SearchResultsDisplay;

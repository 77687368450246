import { Alert } from '@mui/material';
import React from 'react';
import './alertStyle.css';

const UrgentCaseAlert = () => {
  return (
    <Alert severity="warning" variant="outlined" className="pulse-alert blink__box">
      This is a urgent case. Call station when dispatched
    </Alert>
  );
};

export default UrgentCaseAlert;

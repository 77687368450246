import React from 'react';
import { Box } from '@mui/material';

const FormLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {children}
    </Box>
  );
};

export default FormLayout;

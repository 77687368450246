export const formatAddress = (address) => {
  if (!address) return '';

  const addressParts = address.split(' ');

  const postalCodeAndStreet = addressParts.slice(0, 2).join(' ');
  const streetAddress = addressParts.slice(2).join(' ');

  return `${postalCodeAndStreet}\n${streetAddress}`;
};

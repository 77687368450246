import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LogoutButton from '../Button/LogoutButton';

const LogOutDialog = ({ handleClose }) => {
  const { instance } = useMsal();
  const [open, setOpen] = useState(false);

  const handleLogoutRedirect = () => {
    localStorage.clear();
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <>
      <LogoutButton handleClick={() => setOpen(true)} />
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Logger du ut for i dag?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Du er i ferd med å logge ut. Er du sikker? Er alt lagret?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleLogoutRedirect}>
            Logg ut
          </Button>
          <Button variant="outlined" color="error" onClick={() => setOpen(false)} autoFocus>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogOutDialog;

import { useQuery } from '@tanstack/react-query';
import { getRsaCaseData } from '../../api/apiRequests';
import { useAxiosInstance } from '../../api/axiosInstanceTwo';

export const useRsaCaseData = (searchValue) => {
  const axiosInstance = useAxiosInstance();

  return useQuery({
    queryKey: ['rsaCaseData', searchValue],
    queryFn: async () => {
      if (!searchValue) return {};
      const response = await getRsaCaseData(axiosInstance, searchValue);

      console.log('Query response', response);

      return response;
    },
    staleTime: 5 * 60 * 1000,
  });
};

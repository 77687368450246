import React from 'react';
import { Box, Card, IconButton, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { cardSx } from '../../../Styles/defaultStyle';

const SearchResultCard = ({ address, position, companyName, onClose }) => {
  return (
    <Box component={Card} sx={cardSx}>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <IconButton color="error" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="h3" component="h3" sx={{ marginBottom: 1 }}>
          {companyName || 'Sted funnet'}
        </Typography>
        <Typography variant="body1" component="p">
          {address}
        </Typography>
        <Typography variant="body1" component="p">
          Breddegrad: {position[1].toFixed(4)} - Lengdegrad: {position[0].toFixed(4)}
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchResultCard;

import { Box } from '@mui/material';
import React from 'react';
import { ContactName } from './ContactName';
import { ContactNumber } from './ContactNumber';

export const ContactContainer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        width: '100%',
        height: '100%',
      }}
    >
      <ContactName />
      <ContactNumber />
    </Box>
  );
};

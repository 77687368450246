import React from 'react';

import useRegSearchStore from '../../../../hooks/store/useRegSearchStore';
import SearchResultsDisplay from '../../../UI/UserFeedback/SearchResults/SearchResultsDisplay';
import { RegNumSearch } from './RegNumSearch';
import { Card, Typography } from '@mui/material';

export const RegistrationContainer = () => {
  const { pcdData, loading: storeLoading, error: storeError } = useRegSearchStore();

  return (
    <>
      <RegNumSearch />
      {pcdData && Object.keys(pcdData).length > 0 && (
        <Card sx={{ boxShadow: 4, padding: 2 }}>
          <Typography variant="h3" marginBottom={2}>
            Resultater:
          </Typography>
          <SearchResultsDisplay hasSearched={true} loading={storeLoading} pcdErrorMessage={storeError} rsaErrorMessage={storeError} />
        </Card>
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, Box, Button, Typography, Alert } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import CancelIcon from '@mui/icons-material/Cancel';

import { useFormikContext } from 'formik';
import useSearchStore from '../../../../hooks/store/useSearchStore';
import { searchAddress } from '../../../../api/azureMapsService';
import handleSelect from '../../../Map/Search/handleSelect';

export const LocationSearch = ({ isMapOpen, onMapToggle }) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSettingValues, setIsSettingValues] = useState(false);
  const setSearchResult = useSearchStore((state) => state.setSearchResult);

  useEffect(() => {
    const fetchResults = async () => {
      if (inputValue.length > 2) {
        setLoading(true);
        const addressResults = await searchAddress(inputValue);
        setResults(addressResults);
        console.log('Address Results:', addressResults);
        setLoading(false);
      } else {
        setResults([]);
      }
    };

    fetchResults();
  }, [inputValue]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const selectedOption = inputValue;
      handleSelect(event, selectedOption, results, inputValue, setSearchResult);
      setInputValue(values?.location?.address);
    }
  };

  const handleOptionSelect = (event, selectedOption) => {
    if (selectedOption) {
      const selectedResult = results.find((result) => result.label === selectedOption);
      console.log('selectedResult', selectedResult);

      if (selectedResult) {
        setIsSettingValues(true);
        setFieldValue('location.address', selectedResult.address || '');
        setFieldValue('location.postalCode', selectedResult.postalCode || '');
        setFieldValue('location.city', selectedResult.city || '');
        setFieldValue('location.country', selectedResult.country || '');
        setFieldValue('location.countryCodeISO', selectedResult.countryCodeISO || '');
        setFieldValue('location.lon', selectedResult.position.lon || '');
        setFieldValue('location.lat', selectedResult.position.lat || '');

        setTimeout(() => {
          setIsSettingValues(false); // Reset flag after a brief delay
        }, 100);
      }
      handleSelect(event, selectedOption, results, inputValue, setSearchResult);
      setInputValue(values?.location?.address);
    }
  };

  const LocationHelperText = ({ address }) => {
    return (
      <Alert variant="filled" severity="success">
        <Typography variant="caption">
          Skade stedet er satt til: <strong>{address}</strong>
        </Typography>
      </Alert>
    );
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Autocomplete
            freeSolo
            options={results.map((result) => result.label)}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={handleOptionSelect}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Søk opp skadested"
                variant="outlined"
                fullWidth
                onKeyDown={handleKeyPress}
                error={Boolean(errors.location?.address && !isSettingValues)}
                helperText={errors.location?.address && !isSettingValues}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => {
              const result = results.find((result) => result.label === option);
              return (
                <li {...props} key={result.key}>
                  {result.label}
                </li>
              );
            }}
          />
        </Box>
        <Box sx={{ marginLeft: '8px', flexShrink: 0 }}>
          <Button onClick={onMapToggle} variant="contained">
            {isMapOpen ? <CancelIcon /> : <MapIcon />}
          </Button>
        </Box>
      </Box>
      {!isMapOpen && values.location.address ? <LocationHelperText address={values.location.address} /> : null}
    </Box>
  );
};

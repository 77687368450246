import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { contactChannel } from '../../../../options/contactChannel';

export const ContactChannel = () => {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <Tooltip title="Vennligst velg hvor oppringeren ringer fra" placement="top" arrow>
      <Autocomplete
        id="channel-autocomplete"
        options={contactChannel}
        getOptionLabel={(option) => option.name || ''}
        value={contactChannel.find((option) => option.name === values.onSiteContact?.channel) || null}
        onChange={(_event, newValue) => {
          setFieldValue('onSiteContact.channel', newValue ? newValue.name : '');
        }}
        renderInput={(params) => <TextField {...params} label="Kanal" variant="outlined" fullWidth error={Boolean(errors.onSiteContact?.channel)} helperText={errors.onSiteContact?.channel || ''} />}
      />
    </Tooltip>
  );
};

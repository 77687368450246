import * as Yup from 'yup';

export const formValidation = Yup.object({
  registrationNumber: Yup.string().required('Registreringsnummer er påkrevd'),
  pcdData: Yup.object({
    insuranceCompany: Yup.string().required('Forsikringsselskap er påkrevd'),
    lastName: Yup.string().required('Etternavn er påkrevd'),
    make: Yup.string().required('Merke er påkrevd'),
    model: Yup.string().required('Modell er påkrevd'),
    registrationNumber: Yup.string().required('Registreringsnummer er påkrevd i pcdData'),
    registrationNumberCountry: Yup.string().required('Land er påkrevd i pcdData'),
  }),
  priority: Yup.string().required('Prioritet er påkrevd'),
  onSiteContact: Yup.object({
    contactName: Yup.string().required('Kontaktpersonens navn er påkrevd'),
    phone: Yup.string().required('Telefonnummer er påkrevd'),
    channel: Yup.string().required('Kanal er påkrevd'),
  }),
  incident: Yup.object().shape({
    incidentCause: Yup.string().required('Dekningsårsak er påkrevd'),
    incidentDescription: Yup.string().nullable(),
  }),
  location: Yup.object().shape({
    address: Yup.string().required('Adresse er påkrevd'),
    postalCode: Yup.string().nullable(),
    city: Yup.string().required('By er påkrevd'),
    country: Yup.string().required('Land er påkrevd'),
    countryCodeISO: Yup.string().required('Landskode er påkrevd'),
    lon: Yup.string().required('Lengdegrad er påkrevd'),
    lat: Yup.string().required('Breddegrad er påkrevd'),
  }),
});

import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import HeaderDisplay from '../reviewPage/Section/HeaderDisplay';

const PageHeader = () => {
  const location = useLocation();

  const getHeading = (pathname) => {
    switch (pathname) {
      case '/':
        return 'Innlogget bruker';
      case '/case':
        return 'Opprett sak';
      case '/review':
      case pathname.startsWith('/review/') && pathname:
        return 'Vurder sak';
      default:
        return 'Sidetittel';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        backgroundColor: 'white',
        boxShadow: 1,
      }}
    >
      <Typography variant="h1">{getHeading(location.pathname)}</Typography>
      {location.pathname.startsWith('/review/') ? <HeaderDisplay /> : ''}
    </Box>
  );
};

export default PageHeader;

import React from 'react';

import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton = ({ handleClick }) => {
  return (
    <IconButton color="error" onClick={handleClick}>
      <LogoutIcon />
    </IconButton>
  );
};

export default LogoutButton;

import { Box, Card, Typography } from '@mui/material';
import React from 'react';

const InfoSection = ({ formValues, error }) => {
  console.log('InfoSection, errors: ', error);
  console.log('InfoSection, FormValues: ', formValues);

  return (
    <Box component={Card} sx={{ marginBottom: 2 }}>
      <Typography></Typography>
      <Typography></Typography>
      <Typography></Typography>
    </Box>
  );
};

export default InfoSection;

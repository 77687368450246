import { create } from 'zustand';

const useRegSearchStore = create((set) => ({
  pcdData: {},
  caseData: [],
  rsaData: [],
  loading: false,
  error: null,
  hasSearched: false,
  countryCode: 'NO',

  setPcdData: (data) => set({ pcdData: data, hasSearched: true }),
  setCaseData: (data) => set({ caseData: data, hasSearched: true }),
  setRsaData: (data) => set({ rsaData: data, hasSearched: true }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setCountryCode: (code) => set({ countryCode: code }),

  reset: () =>
    set({
      pcdData: null,
      caseData: null,
      rsaData: null,
      hasSearched: false,
      countryCode: 'NO',
      loading: false,
      error: null,
    }),
}));

export default useRegSearchStore;

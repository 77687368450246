export const createCaseInitialValuies = {
  registrationNumber: '',
  pcdData: {
    registrationNumberCountry: '',
    address: '',
    bodyType: '',
    city: '',
    colour: '',
    dateOfFirstRegistration: '',
    fuelType: '',
    insuranceCompany: '',
    lastName: '',
    length: '',
    make: '',
    model: '',
    numberOfAxlesInOperation: '',
    organizationNumber: '' || null,
    totalNumberOfAxles: '',
    vin: '',
    weight: '',
    width: '',
    year: '',
    zip: '',
  },
  onSiteContact: {
    contactName: '',
    countryCode: '+47',
    phone: '',
  },
  channel: '',
  searchTerm: '',
  location: {
    locationMessage: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    countryCodeISO: '',
    lon: '',
    lat: '',
  },
  incident: {
    incidentCause: '',
    incidentDescription: '',
  },
  priority: '3',
  dateTime: null,
};

import { Button } from '@mui/material';
import React from 'react';

const SalesForceButton = ({ id }) => {
  const handleViewCase = () => {
    const url = process.env.REACT_APP_MISSING_DATA + `${id}/view`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Button key="sf_button" variant="contained" onClick={handleViewCase}>
      Vis i Salesforce
    </Button>
  );
};

export default SalesForceButton;

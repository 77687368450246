import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import { createCaseInitialValuies } from '../initialvalues/createCaseInitialValuies';
import { formValidation } from '../validation/formValidation';
import { useHandleForm } from './useHandleForm';
import { CasePrioritySelector, ContactChannel, ContactContainer, LocationContainer } from '../../../components/form';
import { FormWrapper } from '../../../components/form/FormWrapper';
import { CoverCauseContainer } from '../../../components/form/formInput/coverCauseInformation/CoverCauseContainer';
import { RegistrationContainer } from '../../../components/form/formInput/registrationNumber/RegistrationContainer';

export const CaseForm = () => {
  const { onSubmit } = useHandleForm();

  return (
    <>
      <Formik
        initialValues={createCaseInitialValuies}
        validationSchema={formValidation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log('Formik values before submission:', values);
          onSubmit(values, { setSubmitting, resetForm });
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <>
            {/* Log Formik values */}
            <FormikValuesLogger values={values} />
            {/* Your form content */}
            <FormWrapper>
              <ContactChannel />
              <ContactContainer />
              <RegistrationContainer />
              <CoverCauseContainer />
              <LocationContainer />
              <CasePrioritySelector />
              <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                Opprett sak
              </Button>
            </FormWrapper>
          </>
        )}
      </Formik>
    </>
  );
};

// Custom component to log Formik values
const FormikValuesLogger = ({ values }) => {
  useEffect(() => {
    console.log('Formik values updated:', values);
  }, [values]); // This will log every time the values change

  return null; // This component doesn't need to render anything
};

import { create } from 'zustand';

const initialSearchResult = {
  key: null,
  type: null,
  name: null,
  address: null,
  lat: null,
  lon: null,
  businessName: null,
  streetNumber: null,
  street: null,
  countryCode: null,
  countryCodeISO: null,
  countrySubdivision: null,
  postalCode: null,
  country: null,
  freeformAddress: null,
  positionString: null,
};

const useSearchStore = create((set) => ({
  searchResult: { ...initialSearchResult },
  searchPerformed: false,

  setSearchResult: (result) =>
    set({
      searchResult: { ...initialSearchResult, ...result },
      searchPerformed: true,
    }),

  clearSearchResult: () =>
    set({
      searchResult: { ...initialSearchResult },
      searchPerformed: false,
    }),
}));

export default useSearchStore;

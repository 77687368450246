import React from 'react';
import { CoverCauseDescription } from './CoverCauseDescription';
import { CoverCauseSelector } from './CoverCauseSelector';

export const CoverCauseContainer = () => {
  return (
    <>
      <CoverCauseDescription />
      <CoverCauseSelector />
    </>
  );
};

import React from 'react';
import { useReviewCaseValidation } from '../../hooks/validation/useReviewCaseValidation';
import Section from '../reviewPage/Section/Section';
import ValidatedTextField from '../reviewPage/textField/ValidatedTextField';
import FormLayout from '../reviewPage/FormLayout';
import InfoSection from '../reviewPage/Section/InfoSection';
import { Box } from '@mui/material';
import ReviewFormButton from '../Button/ReviewFormButton';

export const CaseDetailsContainer = ({ data }) => {
  const { formValues, errors, isComplete } = useReviewCaseValidation(data);

  console.log('Review page caseData:', data);

  return (
    <Box>
      <InfoSection formValues={formValues} error={errors} />

      <FormLayout>
        {/* Kjøretøy Seksjon */}
        <Section heading="Kjøretøyinfo" hasError={Boolean(errors['car'])} isComplete={isComplete('car')}>
          <ValidatedTextField name="car.model" label="Bilmodell" value={formValues.car?.model || ''} error={Boolean(errors['car.model'])} helperText={errors['car.model']} />
          <ValidatedTextField name="car.make" label="Bilmerke" value={formValues.car?.make || ''} error={Boolean(errors['car.make'])} helperText={errors['car.make']} />
          <ValidatedTextField name="car.type" label="Biltype" value={formValues.car?.type || ''} error={Boolean(errors['car.type'])} helperText={errors['car.type']} />
          <ValidatedTextField name="car.insurance" label="Forsikring" value={formValues.car?.insurance || ''} error={Boolean(errors['car.insurance'])} helperText={errors['car.insurance']} />
          <ValidatedTextField name="car.registrationNumber" label="Registreringsnummer" value={formValues.car?.registrationNumber || ''} error={Boolean(errors['car.registrationNumber'])} helperText={errors['car.registrationNumber']} />
        </Section>

        {/* Eier Seksjon */}
        <Section heading="Forsikringstaker" hasError={Boolean(errors['owner'])} isComplete={isComplete('owner')}>
          {formValues.owner?.name ? (
            <ValidatedTextField name="owner.name" label="Firmanavn" value={formValues.owner?.name || ''} error={Boolean(errors['owner.name'])} helperText={errors['owner.name']} />
          ) : (
            <>
              <ValidatedTextField name="owner.firstName" label="Fornavn" value={formValues.owner?.firstName || ''} error={Boolean(errors['owner.firstName'])} helperText={errors['owner.firstName']} />
              <ValidatedTextField name="owner.lastName" label="Etternavn" value={formValues.owner?.lastName || ''} error={Boolean(errors['owner.lastName'])} helperText={errors['owner.lastName']} />
            </>
          )}
        </Section>

        {/* Avtale Seksjon */}
        <Section heading="Dekningsvalg" hasError={Boolean(errors['agreement'])} isComplete={isComplete('agreement')}>
          <ValidatedTextField name="agreement.coverCause" label="Dekningsårsak" value={formValues.agreement?.coverCause || ''} error={Boolean(errors['agreement.coverCause'])} helperText={errors['agreement.coverCause']} />
          <ValidatedTextField name="agreement.coverageId" label="Deknings-ID" value={formValues.agreement?.coverageId || ''} error={Boolean(errors['agreement.coverageId'])} helperText={errors['agreement.coverageId']} />
          <ValidatedTextField name="agreement.coverageInfo" label="Dekningsinformasjon" value={formValues.agreement?.coverageInfo || ''} error={Boolean(errors['agreement.coverageInfo'])} helperText={errors['agreement.coverageInfo']} />
        </Section>

        {/* Stasjon Seksjon */}
        <Section heading="Stasjon" hasError={Boolean(errors['station'])} isComplete={isComplete('station')}>
          <ValidatedTextField name="station.stationId" label="Stasjons-ID" value={formValues.station?.stationId || ''} error={Boolean(errors['station.stationId'])} helperText={errors['station.stationId']} />
          <ValidatedTextField name="station.stationName" label="Stasjonsnavn" value={formValues.station?.stationName || ''} error={Boolean(errors['station.stationName'])} helperText={errors['station.stationName']} />
          <ValidatedTextField
            name="station.priority"
            label="Prioritet"
            value={formValues.station?.priority === '5. FORHÅNDSBESTILT' ? `${formValues.station.priority} - Tid: ${formValues.station.time || 'Null'}. Dato: ${formValues.station.date || 'Null'}` : formValues.station?.priority || ''}
            error={Boolean(errors['station.priority'])}
            helperText={errors['station.priority']}
          />
        </Section>

        {/* Destinasjon Seksjon */}
        <Section heading="Verksted" hasError={Boolean(errors['destination'])} isComplete={isComplete('destination')}>
          <ValidatedTextField
            name="destination.destinationAddress"
            label="Destinasjonsadresse"
            value={formValues.destination?.destinationAddress || ''}
            error={Boolean(errors['destination.destinationAddress'])}
            helperText={errors['destination.destinationAddress']}
          />
          <ValidatedTextField name="destination.destinationCity" label="Destinasjonsby" value={formValues.destination?.destinationCity || ''} error={Boolean(errors['destination.destinationCity'])} helperText={errors['destination.destinationCity']} />
        </Section>
      </FormLayout>

      <ReviewFormButton isComplete={Object.keys(errors).length === 0} caseId={formValues.caseDetails?.caseId} />
    </Box>
  );
};

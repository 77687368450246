import React from 'react';

import { Box, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import { useFormikContext } from 'formik';
import { priorityNO } from '../../../../options/priority';
import { CaseDate } from './CaseDate';

export const CasePrioritySelector = () => {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <Tooltip title="Velg prioritert" placement="top">
      <FormControl fullWidth variant="outlined" error={Boolean(errors.priority && errors.priority)}>
        <InputLabel id="priority-select-label">Prioritet</InputLabel>
        <Select labelId="priority-select-label" id="priority-select" value={values.priority || ''} onChange={(e) => setFieldValue('priority', e.target.value)} onBlur={() => setFieldValue('priority', values.priority)} label="Priority">
          {priorityNO.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <span>
                {option.label}{' '}
                <small>
                  <i>({option.tooltip})</i>
                </small>
              </span>
            </MenuItem>
          ))}
        </Select>

        {values.priority === '1' && (
          <Box sx={{ display: 'flex', gap: 1, justifyItems: 'center', marginTop: 1 }}>
            <ReportGmailerrorredIcon color="error" />
            <Typography variant="body1" color="error">
              Dette er kun for politijobb.
            </Typography>
          </Box>
        )}
        {values.priority === '2' && (
          <Box sx={{ display: 'flex', gap: 1, justifyItems: 'center', marginTop: 1 }}>
            <ReportGmailerrorredIcon color="error" />
            <Typography variant="body1" color="error">
              Kontakt stasjonen på telefon etter at saken er dispatchert.
            </Typography>
          </Box>
        )}

        {values.priority === '5' && <CaseDate name="dateTime" />}
      </FormControl>
    </Tooltip>
  );
};

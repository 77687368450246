import React, { useState, useEffect } from 'react';
import { InputAdornment, TextField, MenuItem, FormControl, CircularProgress, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { useFormikContext } from 'formik';
import useRegSearchStore from '../../../../hooks/store/useRegSearchStore';
import { registrationFormat } from '../../../../utils/format/registrationFormat';
import { regCode } from '../../../../options/regCode';
import { useRegistrationSearch } from '../../../../hooks/Search/useRegistrationSearch';

export const RegNumSearch = () => {
  const { values, setFieldValue, errors } = useFormikContext();
  const [inputValue, setInputValue] = useState(values.registrationNumber);
  const [searchStatus, setSearchStatus] = useState('idle');
  const [inputError, setInputError] = useState('');

  const { fetchRegData } = useRegistrationSearch();
  const { setLoading, setError, reset, pcdData, countryCode, setCountryCode } = useRegSearchStore();

  const validateRegistrationNumber = (number, country) => {
    const pattern = registrationFormat[country];
    return pattern && !pattern.test(number) ? `Ugyldig format for ${country}` : '';
  };

  const handleSearch = async (searchValue) => {
    const validationError = validateRegistrationNumber(searchValue, countryCode);
    setInputError(validationError);

    if (validationError) {
      reset();
      return;
    }

    try {
      setSearchStatus('loading');
      setLoading(true);
      const request = await fetchRegData(searchValue); //eslint-disable-line

      setSearchStatus('idle');
    } catch (err) {
      setSearchStatus('error');
      setInputError('Registration not found (404)');
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!inputValue) return;

    handleSearch(inputValue);
  }, [inputValue, countryCode]); // eslint-disable-line

  const handleChange = (e) => {
    const upperCaseValue = e.target.value.toUpperCase();
    setInputValue(upperCaseValue);
    setFieldValue('registrationNumber', upperCaseValue);
  };

  useEffect(() => {
    console.log('pcdData', pcdData);
  }, [pcdData]);

  return (
    <Tooltip title="Søk på registreringsnummer" placement="top" arrow>
      <FormControl variant="outlined" fullWidth error={!!inputError || (errors.registrationNumber && errors.registrationNumber)}>
        <TextField
          variant="outlined"
          name="registrationNumber"
          label="Skriv inn registreringsnummer"
          placeholder="Skriv inn registreringsnummer"
          value={inputValue}
          onChange={handleChange}
          error={!!inputError || (errors.registrationNumber && errors.registrationNumber)}
          helperText={inputError || (errors.registrationNumber && errors.registrationNumber) || ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextField
                  select
                  value={countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                  variant="standard"
                >
                  {regCode.map(({ code, name }) => (
                    <MenuItem key={code} value={code}>
                      ({name}) {code}
                    </MenuItem>
                  ))}
                </TextField>
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">{searchStatus === 'loading' ? <CircularProgress size={24} /> : searchStatus === 'error' ? <ErrorIcon color="error" /> : null}</InputAdornment>,
          }}
        />
      </FormControl>
    </Tooltip>
  );
};

const categorizeData = (data) => {
  const {
    contact,
    account,
    vehicle,
    location,
    station,
    coverCause,
    coverage,
    priority,
    workshop,
    canProvideHotel,
    canProvideRentalCar,
    canProvideTransportation,
    caseNumber,
    caseId,
    registrationNumber,
    isProblemCovered,
    channel,
  } = data;

  // Categorize the data
  const categorizedData = {
    caseDetails: {
      caseId: caseId || '',
      caseNumber: caseNumber || '',
      contactChannel: channel || '',
      registrationNumber: registrationNumber || '',
    },
    owner: {
      firstName: account?.firstName || '',
      lastName: account?.lastName || '',
      name: account?.name || '',
      billingAddress: {
        street: account?.billingStreet || '',
        postalCode: account?.billingPostalCode || '',
        city: account?.billingCity || '',
      },
    },
    customer: {
      contactName: contact?.name || '',
      contactNumber: contact?.phoneNumber || '',
    },
    car: {
      model: vehicle?.model || '',
      make: vehicle?.make || '',
      type: vehicle?.type || '',
      insurance: vehicle?.insurance || '',
      registrationNumber: registrationNumber || '',
    },
    agreement: {
      coverCause: coverCause || '',
      coverageId: coverage?.coverageId || '',
      coverageInfo: coverage?.coverageInfo || '',
      isProblemCovered: isProblemCovered || false,
    },
    extraService: {
      hotel: canProvideHotel ? true : false,
      rentalCar: canProvideRentalCar ? true : false,
      transportation: canProvideTransportation ? true : false,
    },
    location: {
      address: location?.address || '',
      postalCode: location?.zip || '',
      city: location?.city || '',
    },
    station: {
      stationId: station?.stationId || '',
      stationName: station?.stationName || '',
      priority: priority || '',
    },
    destination: {
      destinationAddress: workshop?.address || '',
      destinationCity: workshop?.city || '',
    },
  };

  return categorizedData;
};

export default categorizeData;

import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../auth/authConfig';
import { Unauthenticated } from './Unauthenticated';
import Authenticated from './Authenticated';
import { useNavigate } from 'react-router';

const PageLayout = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const navigate = useNavigate();

  const handleRedirect = () => {
    console.log('Attempting login...');
    instance
      .loginPopup({
        ...loginRequest,
        prompt: 'create',
      })
      .then(() => {
        console.log('Login successful');
        navigate('/case');
      })
      .catch((error) => console.error('Login error:', error));
  };

  return <>{activeAccount ? <Authenticated /> : <Unauthenticated onClick={handleRedirect} />}</>;
};

export default PageLayout;

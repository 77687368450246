import { Box } from '@mui/material';
import { Form } from 'formik';
import React from 'react';

export const FormWrapper = ({ children }) => {
  return (
    <Form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {children}
      </Box>
    </Form>
  );
};

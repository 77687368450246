export const contactChannel = [
  { value: 1, name: 'Gjensidige', covervalue: 'Gjensidige_veihjelp' },
  { value: 2, name: 'Nissan', covervalue: 'Nissan' },
  { value: 3, name: 'Mekonomen', covervalue: 'Mekonomen' },
  { value: 4, name: 'JBF', covervalue: 'JBF' },
  { value: 5, name: 'Meca', covervalue: 'Meca' },
  { value: 6, name: 'Arval', covervalue: 'Arval' },
  { value: 7, name: 'Frende', covervalue: 'Frende' },
  { value: 8, name: 'SnapDrive', covervalue: 'SnapDrive' },
  { value: 9, name: 'Eika', covervalue: 'Eika' },
  { value: 10, name: 'Kia', covervalue: 'Kia' },
  { value: 11, name: 'Landkredittforsikring', covervalue: 'Landkredittforsikring' },
  { value: 12, name: 'Evoli', covervalue: 'Evoli' },
  { value: 13, name: 'Pirelli', covervalue: 'Pirelli' },
  { value: 14, name: 'Dackteam', covervalue: 'Dackteam' },
  { value: 15, name: 'Brvaluegestone', covervalue: 'Brvaluegestone' },
  { value: 16, name: 'Autollecom', covervalue: 'Autollecom' },
  { value: 17, name: 'Drivalialease', covervalue: 'Drivalialease' },
  { value: 18, name: 'Renault', covervalue: 'Renault' },
  { value: 19, name: 'Bestcaravan', covervalue: 'Bestcaravan' },
  { value: 20, name: 'REDGO', covervalue: 'REDGO' },
];

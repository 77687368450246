export const coverCauseEN = [
  { value: 'Abnormal noise from brakes', label: 'Abnormal noise from brakes' },
  { value: 'Abnormal noise from engine', label: 'Abnormal noise from engine' },
  { value: 'Abnormal noise from transmission', label: 'Abnormal noise from transmission' },
  { value: 'Air suspension broken', label: 'Air suspension broken' },
  { value: 'Battery', label: 'Battery' },
  { value: 'Bearing broken', label: 'Bearing broken' },
  { value: 'Brakes', label: 'Brakes' },
  { value: 'Brakes not connecting', label: 'Brakes not connecting' },
  { value: 'Brakes not disconnecting', label: 'Brakes not disconnecting' },
  { value: 'Broken spring', label: 'Broken spring' },
  { value: 'Collision', label: 'Collision' },
  { value: 'Collision with another vehicle', label: 'Collision with another vehicle' },
  { value: 'Collision with moose/deer', label: 'Collision with moose/deer' },
  { value: 'Collision with other animal', label: 'Collision with other animal' },
  { value: 'Collision with stationary object', label: 'Collision with stationary object' },
  { value: 'Damaged', label: 'Damaged' },
  { value: 'Does not start', label: 'Does not start' },
  { value: 'Does not start, has no electricity, at home address', label: 'Does not start, has no electricity, at home address' },
  { value: 'Does not start, has no electricity, away from home', label: 'Does not start, has no electricity, away from home' },
  { value: 'Door locks not opening', label: 'Door locks not opening' },
  { value: 'Driven off road, drivable', label: 'Driven off road, drivable' },
  { value: 'Driven off road, non-drivable', label: 'Driven off road, non-drivable' },
  { value: 'Electrical', label: 'Electrical' },
  { value: 'Engine', label: 'Engine' },
  { value: 'Engine running, car not moving', label: 'Engine running, car not moving' },
  { value: 'Breakdown', label: 'Breakdown' },
  { value: 'Exhaust pipe problem', label: 'Exhaust pipe problem' },
  { value: 'Fire', label: 'Fire' },
  { value: 'Fire when driving', label: 'Fire when driving' },
  { value: 'Fire when stationary', label: 'Fire when stationary' },
  { value: 'Fuel', label: 'Fuel' },
  { value: 'Headlights/other lights broken', label: 'Headlights/other lights broken' },
  { value: 'Heating/air con not working', label: 'Heating/air con not working' },
  { value: 'Hydraulics problem', label: 'Hydraulics problem' },
  { value: 'Ignition/steering lock stuck', label: 'Ignition/steering lock stuck' },
  { value: 'Keys', label: 'Keys' },
  { value: 'Keys broken', label: 'Keys broken' },
  { value: 'Keys inside locked car', label: 'Keys inside locked car' },
  { value: 'Keys inside locked car, engine running', label: 'Keys inside locked car, engine running' },
  { value: 'Keys lost', label: 'Keys lost' },
  { value: 'Leaking air', label: 'Leaking air' },
  { value: 'Leaking coolant', label: 'Leaking coolant' },
  { value: 'Leaking fuel', label: 'Leaking fuel' },
  { value: 'Leaking oil', label: 'Leaking oil' },
  { value: 'Lost electricity when driving', label: 'Lost electricity when driving' },
  { value: 'Lost power suddenly', label: 'Lost power suddenly' },
  { value: 'Malfunction of electrical device', label: 'Malfunction of electrical device' },
  { value: 'Out of fuel', label: 'Out of fuel' },
  { value: 'Problem connecting to trailer', label: 'Problem connecting to trailer' },
  { value: 'Puncture when stationary', label: 'Puncture when stationary' },
  { value: 'Puncture while driving', label: 'Puncture while driving' },
  { value: 'Smoke/Overheating', label: 'Smoke/Overheating' },
  { value: 'Stalled while driving', label: 'Stalled while driving' },
  { value: 'Steering', label: 'Steering' },
  { value: 'Steering went heavy suddenly', label: 'Steering went heavy suddenly' },
  { value: 'Stuck on road / driveway', label: 'Stuck on road / driveway' },
  { value: 'Suspension / chassis', label: 'Suspension / chassis' },
  { value: 'Tire', label: 'Tire' },
  { value: 'Trailer broken', label: 'Trailer broken' },
  { value: 'Transmission', label: 'Transmission' },
  { value: 'Transportation of drivable car', label: 'Transportation of drivable car' },
  { value: 'Transportation of non-drivable car', label: 'Transportation of non-drivable car' },
  { value: 'Vehicle - Engine damage', label: 'Vehicle - Engine damage' },
  { value: 'Vehicle - Fire', label: 'Vehicle - Fire' },
  { value: 'Vehicle - Liability', label: 'Vehicle - Liability' },
  { value: 'Vehicle - Off Road', label: 'Vehicle - Off Road' },
  { value: 'Vehicle - Theft/Vandalism', label: 'Vehicle - Theft/Vandalism' },
  { value: 'Vehicle - Wildlife accident', label: 'Vehicle - Wildlife accident' },
  { value: 'Warning lights', label: 'Warning lights' },
  { value: 'Warning lights Red', label: 'Warning lights Red' },
  { value: 'Warning lights Yellow', label: 'Warning lights Yellow' },
  { value: 'Wheel fell off', label: 'Wheel fell off' },
  { value: 'Windscreen / window broken', label: 'Windscreen / window broken' },
  { value: 'Wrong fuel', label: 'Wrong fuel' },
];

export const coverCauseNO = [
  { value: 'Unormal støy fra bremser', label: 'Unormal støy fra bremser' },
  { value: 'Unormal støy fra motor', label: 'Unormal støy fra motor' },
  { value: 'Unormal støy fra girkasse', label: 'Unormal støy fra girkasse' },
  { value: 'Luftfjæring ødelagt', label: 'Luftfjæring ødelagt' },
  { value: 'Batteri', label: 'Batteri' },
  { value: 'Lager ødelagt', label: 'Lager ødelagt' },
  { value: 'Bremser', label: 'Bremser' },
  { value: 'Bremser kobler ikke', label: 'Bremser kobler ikke' },
  { value: 'Bremser kobler fra', label: 'Bremser kobler fra' },
  { value: 'Brudd i fjær', label: 'Brudd i fjær' },
  { value: 'Kollisjon', label: 'Kollisjon' },
  { value: 'Kollisjon med annet kjøretøy', label: 'Kollisjon med annet kjøretøy' },
  { value: 'Kollisjon med elg/hjort', label: 'Kollisjon med elg/hjort' },
  { value: 'Kollisjon med annet dyr', label: 'Kollisjon med annet dyr' },
  { value: 'Kollisjon med stasjonært objekt', label: 'Kollisjon med stasjonært objekt' },
  { value: 'Skadet', label: 'Skadet' },
  { value: 'Starter ikke', label: 'Starter ikke' },
  { value: 'Starter ikke, har ingen strøm, hjemmeadresse', label: 'Starter ikke, har ingen strøm, hjemmeadresse' },
  { value: 'Starter ikke, har ingen strøm, borte fra hjemmet', label: 'Starter ikke, har ingen strøm, borte fra hjemmet' },
  { value: 'Dørlåser åpner ikke', label: 'Dørlåser åpner ikke' },
  { value: 'Kjørt av veien, kjørbar', label: 'Kjørt av veien, kjørbar' },
  { value: 'Kjørt av veien, ikke-kjørbar', label: 'Kjørt av veien, ikke-kjørbar' },
  { value: 'Elektrisk', label: 'Elektrisk' },
  { value: 'Motor', label: 'Motor' },
  { value: 'Motoren går, bilen beveger seg ikke', label: 'Motoren går, bilen beveger seg ikke' },
  { value: 'Driftstopp', label: 'Driftstopp' },
  { value: 'Eksosrørproblem', label: 'Eksosrørproblem' },
  { value: 'Brann', label: 'Brann' },
  { value: 'Brann når du kjører', label: 'Brann når du kjører' },
  { value: 'Brann når bilen står stille', label: 'Brann når bilen står stille' },
  { value: 'Drivstoff', label: 'Drivstoff' },
  { value: 'Frontlys/andre lys ødelagt', label: 'Frontlys/andre lys ødelagt' },
  { value: 'Oppvarming/klima fungerer ikke', label: 'Oppvarming/klima fungerer ikke' },
  { value: 'Hydraulikkproblem', label: 'Hydraulikkproblem' },
  { value: 'Tenning/låsing sitter fast', label: 'Tenning/låsing sitter fast' },
  { value: 'Nøkler', label: 'Nøkler' },
  { value: 'Nøkler ødelagt', label: 'Nøkler ødelagt' },
  { value: 'Nøkler inne i låst bil', label: 'Nøkler inne i låst bil' },
  { value: 'Nøkler inne i låst bil, motoren går', label: 'Nøkler inne i låst bil, motoren går' },
  { value: 'Nøkler mistet', label: 'Nøkler mistet' },
  { value: 'Leker luft', label: 'Leker luft' },
  { value: 'Leker kjølevæske', label: 'Leker kjølevæske' },
  { value: 'Leker drivstoff', label: 'Leker drivstoff' },
  { value: 'Leker olje', label: 'Leker olje' },
  { value: 'Mistede strøm mens du kjørte', label: 'Mistede strøm mens du kjørte' },
  { value: 'Mistede kraft plutselig', label: 'Mistede kraft plutselig' },
  { value: 'Feil på elektrisk enhet', label: 'Feil på elektrisk enhet' },
  { value: 'Tom for drivstoff', label: 'Tom for drivstoff' },
  { value: 'Problem med tilkobling til tilhenger', label: 'Problem med tilkobling til tilhenger' },
  { value: 'Punktering når bilen står stille', label: 'Punktering når bilen står stille' },
  { value: 'Punktering mens du kjører', label: 'Punktering mens du kjører' },
  { value: 'Røyk/overoppheting', label: 'Røyk/overoppheting' },
  { value: 'Stoppet mens du kjørte', label: 'Stoppet mens du kjørte' },
  { value: 'Styring', label: 'Styring' },
  { value: 'Styring ble tung plutselig', label: 'Styring ble tung plutselig' },
  { value: 'Satt fast på veien/innkjørsel', label: 'Satt fast på veien/innkjørsel' },
  { value: 'Fjæring/chassis', label: 'Fjæring/chassis' },
  { value: 'Dekk', label: 'Dekk' },
  { value: 'Tilhenger ødelagt', label: 'Tilhenger ødelagt' },
  { value: 'Girkasse', label: 'Girkasse' },
  { value: 'Transport av kjørbar bil', label: 'Transport av kjørbar bil' },
  { value: 'Transport av ikke-kjørbar bil', label: 'Transport av ikke-kjørbar bil' },
  { value: 'Kjøretøy - Motorskade', label: 'Kjøretøy - Motorskade' },
  { value: 'Kjøretøy - Brann', label: 'Kjøretøy - Brann' },
  { value: 'Kjøretøy - Ansvar', label: 'Kjøretøy - Ansvar' },
  { value: 'Kjøretøy - Av veien', label: 'Kjøretøy - Av veien' },
  { value: 'Kjøretøy - Tyveri/Vandalism', label: 'Kjøretøy - Tyveri/Vandalism' },
  { value: 'Kjøretøy - Dyrekollisjon', label: 'Kjøretøy - Dyrekollisjon' },
  { value: 'Varsellamper', label: 'Varsellamper' },
  { value: 'Varsellamper Rød', label: 'Varsellamper Rød' },
  { value: 'Varsellamper Gul', label: 'Varsellamper Gul' },
  { value: 'Hjul falt av', label: 'Hjul falt av' },
  { value: 'Vindus-/vindu ødelagt', label: 'Vindus-/vindu ødelagt' },
  { value: 'Feil drivstoff', label: 'Feil drivstoff' },
];

export const coverCauses = [
  // Collision
  { id: 101, value: 'Collision', label: 'Kollisjon', category: 'Collision', lvl: 3 },
  { id: 102, value: 'Collision with another vehicle', label: 'Kollisjon med annen bil', category: 'Collision', lvl: 4 },
  { id: 103, value: 'Collision with stationary object', label: 'Kollisjon med gjenstand', category: 'Collision', lvl: 4 },
  { id: 104, value: 'Collision with moose/deer', label: 'Kollisjon med vilt', category: 'Collision', lvl: 4 },
  { id: 105, value: 'Collision with other animal', label: 'Kollisjon med annet dyr', category: 'Collision', lvl: 4 },

  // Other accident
  { id: 106, value: 'Other accident', label: 'Annet uhell', category: 'Other accident', lvl: 3 },
  { id: 107, value: 'Driven off road, drivable', label: 'Utforkjøring, trolig kjørbar', category: 'Other accident', lvl: 4 },
  { id: 108, value: 'Driven off road, non-drivable', label: 'Utforkjøring, ikke kjørbar', category: 'Other accident', lvl: 4 },
  { id: 109, value: 'Stuck on road / driveway', label: 'Fastkjørt på vei', category: 'Other accident', lvl: 4 },

  // Body / accessories
  { id: 201, value: 'Body / accessories', label: 'Chassis/ påbygg', category: 'Body / accessories', lvl: 3 },
  { id: 202, value: 'Door locks not opening', label: 'Utelåst', category: 'Body / accessories', lvl: 4 },
  { id: 203, value: 'Exhaust pipe problem', label: 'Eksosanlegg', category: 'Body / accessories', lvl: 4 },
  { id: 204, value: 'Windscreen / window broken', label: 'Rute / vindusvisker', category: 'Body / accessories', lvl: 4 },

  // Brakes
  { id: 205, value: 'Brakes', label: 'Bremser', category: 'Brakes', lvl: 3 },
  { id: 206, value: 'Brakes not connecting', label: 'Bremser virker ikke', category: 'Brakes', lvl: 4 },
  { id: 207, value: 'Brakes not disconnecting', label: 'Bremser sitter fast', category: 'Brakes', lvl: 4 },
  { id: 208, value: 'Abnormal noise from brakes', label: 'Bremser, ulyd', category: 'Brakes', lvl: 4 },

  // Does not start
  { id: 209, value: 'Does not start', label: 'Startvansker', category: 'Does not start', lvl: 3 },
  { id: 210, value: 'Does not start, has no electricity, away from home', label: 'Startvansker på vei', category: 'Does not start', lvl: 4 },
  { id: 211, value: 'Does not start, has no electricity, at home address', label: 'Startvansker hjemme', category: 'Does not start', lvl: 4 },

  // Electrical
  { id: 212, value: 'Electrical problem', label: 'Elektrisk problem', category: 'Electrical', lvl: 3 },
  { id: 213, value: 'Lost electricity when driving', label: 'Mistet strøm under kjøring', category: 'Electrical', lvl: 4 },
  { id: 214, value: 'Malfunction of electrical device', label: 'Feil på elektrisk utstyr', category: 'Electrical', lvl: 4 },
  { id: 215, value: 'Heating/air con not working', label: 'Varme / AC virker ikke', category: 'Electrical', lvl: 4 },
  { id: 216, value: 'Headlights/other lights broken', label: 'Lys', category: 'Electrical', lvl: 4 },

  // Engine
  { id: 217, value: 'Engine failure', label: 'Motorfeil', category: 'Engine', lvl: 3 },
  { id: 218, value: 'Lost power suddenly', label: 'Motorstopp', category: 'Engine', lvl: 4 },
  { id: 219, value: 'Smoke/Overheating', label: 'Røykutvikling', category: 'Engine', lvl: 4 },
  { id: 220, value: 'Abnormal noise from engine', label: 'Ulyd fra motor', category: 'Engine', lvl: 4 },

  // Hydraulics problem
  { id: 246, value: 'Hydraulics problem', label: 'Hydraulikk', category: 'Hydraulics problem', lvl: 3 },

  // Leak
  { id: 221, value: 'Leak', label: 'Lekkasje', category: 'Leak', lvl: 3 },
  { id: 222, value: 'Leaking air', label: 'Luftlekkasje', category: 'Leak', lvl: 4 },
  { id: 223, value: 'Leaking coolant', label: 'Kjølevæskelekkasje', category: 'Leak', lvl: 4 },
  { id: 224, value: 'Leaking oil', label: 'Oljelekkasje', category: 'Leak', lvl: 4 },
  { id: 225, value: 'Leaking fuel', label: 'Drivstofflekkasje', category: 'Leak', lvl: 4 },

  // Other breakdown
  { id: 226, value: 'Other breakdown', label: 'Driftsstans, annet', category: 'Other breakdown', lvl: 3 },
  { id: 227, value: 'Trailer broken', label: 'Tilhenger', category: 'Other breakdown', lvl: 4 },
  { id: 228, value: 'Problem connecting to trailer', label: 'Tilhenger, tilkoblingsproblem', category: 'Other breakdown', lvl: 4 },

  // Steering
  { id: 229, value: 'Steering', label: 'Styring', category: 'Steering', lvl: 3 },
  { id: 230, value: 'Steering went heavy suddenly', label: 'Styring ble plutslig tung', category: 'Steering', lvl: 4 },
  { id: 231, value: 'Ignition/steering lock stuck', label: 'Tenning / rattlås', category: 'Steering', lvl: 4 },

  // Suspension / chassis
  { id: 232, value: 'Suspension / chassis', label: 'Hjuloppheng', category: 'Suspension / chassis', lvl: 3 },
  { id: 233, value: 'Air suspension broken', label: 'Luftfjæring', category: 'Suspension / chassis', lvl: 4 },
  { id: 234, value: 'Broken spring', label: 'Fjæring', category: 'Suspension / chassis', lvl: 4 },
  { id: 235, value: 'Bearing broken', label: 'Hjuloppheng ødelagt', category: 'Suspension / chassis', lvl: 4 },

  // Tire
  { id: 236, value: 'Tire', label: 'Dekk', category: 'Tire', lvl: 3 },
  { id: 237, value: 'Wheel fell off', label: 'Hjul falt av', category: 'Tire', lvl: 4 },
  { id: 238, value: 'Puncture while driving', label: 'Punktering under kjøring', category: 'Tire', lvl: 4 },
  { id: 239, value: 'Puncture when stationary', label: 'Punktert mens parkert', category: 'Tire', lvl: 4 },

  // Transmission
  { id: 240, value: 'Transmission', label: 'Gir / Drivverk / Clutch', category: 'Transmission', lvl: 3 },
  { id: 241, value: 'Engine running, car not moving', label: 'Motor går, bil kjører ikke', category: 'Transmission', lvl: 4 },
  { id: 242, value: 'Abnormal noise from transmission', label: 'Ulyd fra girkasse', category: 'Transmission', lvl: 4 },

  // Warning lights
  { id: 243, value: 'Warning lights', label: 'Varsellampe', category: 'Warning lights', lvl: 3 },
  { id: 244, value: 'Warning lights Red', label: 'Rød varsellampe', category: 'Warning lights', lvl: 4 },
  { id: 245, value: 'Warning lights Yellow', label: 'Gul varsellampe', category: 'Warning lights', lvl: 4 },
];

export const categoryTranslations = {
  Collision: 'Kollisjon',
  'Other accident': 'Annet uhell',
  'Body / accessories': 'Chassis/ påbygg',
  Brakes: 'Bremser',
  'Does not start': 'Startvansker',
  Electrical: 'Elektrisk problem',
  Engine: 'Motorfeil',
  'Hydraulics problem': 'Hydraulikk',
  Leak: 'Lekkasje',
  'Other breakdown': 'Driftsstans, annet',
  Steering: 'Styring',
  'Suspension / chassis': 'Hjuloppheng',
  Tire: 'Dekk',
  Transmission: 'Gir / Drivverk / Clutch',
  'Warning lights': 'Varsellampe',
};

import { create } from 'zustand';

const usePositionStore = create((set) => ({
  position: [10.75, 59.91],
  setPosition: (newPosition) => set({ position: newPosition }),
  updatePosition: (updatedPosition) =>
    set((state) => ({
      position: [updatedPosition[0] || state.position[0], updatedPosition[1] || state.position[1]],
    })),
  clearPosition: () => set({ position: [10.75, 59.91] }),
}));

export default usePositionStore;

import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { apiScopes } from "../auth/authConfig";

export const useAxiosInstance = () => {
  const { instance: msalInstance, accounts } = useMsal();

  const axiosInstance = axios.create({
    baseURL: "",
    headers: { },
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        if (accounts.length === 0) {
          throw new Error("No active account found");
        }
        const account = accounts[0];
        const scopes = config.url && config.url.includes("/pcd") ? apiScopes.pcd : apiScopes.default;
        const response = await msalInstance.acquireTokenSilent({
          scopes,
          account,
        });
        if (config.headers) {
          config.headers.Authorization = `Bearer ${response.accessToken}`;
        }
      } catch (error) {
        console.error("Token acquisition failed", error);
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.loginRedirect();
        } else {
          throw error;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        msalInstance.loginRedirect();
      } else {
        console.error("axiosInstance request error", error);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

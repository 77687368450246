import React, { useEffect, useState } from 'react';
import { FormControl, InputAdornment, IconButton, TextField, MenuItem, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

import { phoneNumberFormat } from '../../../../utils/format/phoneNumerFormat';
import { validatePhoneNumber } from '../../../../pages/newCase/validation/validatePhoneNumber';

export const ContactNumber = () => {
  const { setFieldValue, values, errors } = useFormikContext();
  const [selectedCountry, setSelectedCountry] = useState(values.onSiteContact.countryCode || phoneNumberFormat[0].code);
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    if (values.onSiteContact.phone) {
      setFieldValue('onSiteContact.phone', values.onSiteContact.phone);
    }
    setSelectedCountry(values.onSiteContact.countryCode || phoneNumberFormat[0].code);
  }, [values.onSiteContact.phone, values.onSiteContact.countryCode, setFieldValue]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFieldValue('onSiteContact.phone', value);
    handleValidation(value, selectedCountry);
  };

  const handleCountryChange = (e) => {
    const newCountry = e.target.value;
    setSelectedCountry(newCountry);
    setFieldValue('onSiteContact.countryCode', newCountry);
    handleValidation(values.onSiteContact.phone, newCountry);
  };

  const handleValidation = (number, country) => {
    const validationError = validatePhoneNumber(number, country);
    setInputError(validationError);
  };

  useEffect(() => {
    handleValidation(values.onSiteContact.phone, selectedCountry);
  }, [values.onSiteContact.phone, selectedCountry]);

  return (
    <Tooltip title="Skriv inn kontakttelefonnummer" placement="top">
      <FormControl variant="outlined" fullWidth error={!!inputError || (errors.onSiteContact?.phone && errors.onSiteContact?.phone)}>
        <TextField
          variant="outlined"
          name="onSiteContact.phone"
          placeholder="Kontakt telefonnummer"
          value={values.onSiteContact.phone || ''}
          onChange={handleInputChange}
          error={!!inputError || (errors.onSiteContact?.phone && errors.onSiteContact?.phone)}
          helperText={inputError || (errors.onSiteContact?.phone && errors.onSiteContact?.phone)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextField select value={selectedCountry} onChange={handleCountryChange} variant="standard" style={{ marginRight: 8 }}>
                  {phoneNumberFormat.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      ({option.name}) {option.code}
                    </MenuItem>
                  ))}
                </TextField>
              </InputAdornment>
            ),
            endAdornment: inputError ? (
              <InputAdornment position="end">
                <IconButton>
                  <ErrorIcon color="error" />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
      </FormControl>
    </Tooltip>
  );
};

import { useState, useEffect, useCallback } from 'react';
import categorizeData from '../../components/reviewPage/utils/categorizeData';

export const useReviewCaseValidation = (caseData) => {
  const [formValues] = useState(categorizeData(caseData));
  const [errors, setErrors] = useState({});

  const validateFields = useCallback(() => {
    const newErrors = {};

    Object.keys(formValues).forEach((key) => {
      if (key === 'owner') {
        const owner = formValues.owner;
        const isCompany = !!owner?.name;

        if (isCompany) {
          if (!owner?.name) {
            newErrors['owner.name'] = 'Obligatorisk';
          }
        } else {
          if (!owner?.firstName) {
            newErrors['owner.firstName'] = 'Obligatorisk';
          }
          if (!owner?.lastName) {
            newErrors['owner.lastName'] = 'Obligatorisk';
          }
        }
      } else if (typeof formValues[key] === 'object') {
        Object.keys(formValues[key]).forEach((subKey) => {
          if (key === 'extraService' || (key === 'location' && subKey === 'postalCode')) {
            return;
          }

          if (!formValues[key][subKey]) {
            newErrors[`${key}.${subKey}`] = 'Obligatorisk';
          }
        });
      } else if (!formValues[key]) {
        newErrors[key] = 'Obligatorisk';
      }
    });

    setErrors(newErrors);
  }, [formValues]);

  useEffect(() => {
    validateFields();
  }, [formValues, validateFields]);

  const isComplete = useCallback((sectionKey) => !Object.keys(errors).some((errorKey) => errorKey.startsWith(sectionKey)), [errors]);

  const isCompany = !!formValues.owner?.name;

  return { formValues, errors, isComplete, isCompany };
};

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostRsaCase } from '../../../hooks/query/usePostRsaCase';
import { postRequest } from '../../../utils/request/postRequest';
import { toast } from 'react-toastify';
import useRegSearchStore from '../../../hooks/store/useRegSearchStore';
import useSearchStore from '../../../hooks/store/useSearchStore';

export const useHandleForm = () => {
  const navigate = useNavigate();
  const { mutateAsync } = usePostRsaCase();
  const resetStore = useRegSearchStore((state) => state.reset);
  const clearSearchResult = useSearchStore((state) => state.clearSearchResult);

  const onSubmit = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      try {
        const requestBody = postRequest(values);

        console.log('Submitting request body:', requestBody);
        const response = await mutateAsync(requestBody);
        if (!response?.data?.id) throw new Error('Invalid response from server');
        const id = response.data.id;
        navigate(`/review/${id}`);

        if (id) {
          resetStore();
          clearSearchResult();
          resetForm();
        }
      } catch (error) {
        console.error('Error submitting form:', error);

        const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred.';
        toast.error(errorMessage);
      } finally {
        setSubmitting(false);
      }
    },
    [clearSearchResult, mutateAsync, navigate, resetStore]
  );

  return { onSubmit };
};

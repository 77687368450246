import React from 'react';
import { Box } from '@mui/material';
import Logo from '../../Assets/logo.png';
import LogOutDialog from '../Dialog/LogOutDialog';

const LeftSidebarContent = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 2 / 2,
          padding: 1,
        }}
      >
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <img src={Logo} alt="Logo" style={{ width: '100%' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <LogOutDialog />
        </Box>
      </Box>
    </>
  );
};

export default LeftSidebarContent;

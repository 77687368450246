import React from 'react';
import { Drawer, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SidebarDrawer = ({
  children,
  state,
  position = 'left',
  width = 75,
  onClose,
  isClosable = true,
}) => {
  return (
    <Drawer
      variant="persistent"
      open={state}
      sx={{
        width: width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: width,
          boxSizing: 'border-box',
        },
      }}
      anchor={position}
      onClose={onClose}
    >
      {isClosable && onClose && (
        <Box sx={{ alignSelf: 'flex-end' }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {children}
    </Drawer>
  );
};

export default SidebarDrawer;

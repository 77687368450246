import axios from 'axios';
import { mapSearchResult } from '../utils/mapSearchResults';

const subscriptionKey = process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY;
const baseUrl = process.env.REACT_APP_AZURE_MAP_BASE_URL;

export const searchAddress = async (searchTerm) => {
  try {
    const response = await axios.get(`${baseUrl}/fuzzy/json`, {
      params: {
        'api-version': '1.0',
        countrySet: 'no',
        query: searchTerm,
        maxFuzzyLevel: '4',
        minFuzzyLevel: '2',
        extendedPostalCodesFor: 'PAD,Addr,POI',
        limit: 100,
        'subscription-key': subscriptionKey,
      },
    });

    const results = response.data.results || [];
    console.log('Fetch address function search:', results);
    return results.map((result) => mapSearchResult(result));
  } catch (error) {
    console.error('Error fetching search results:', error);
    return [];
  }
};

export const searchCoordinates = async (lat, lon) => {
  try {
    const response = await axios.get(`${baseUrl}/address/reverse/json`, {
      params: {
        'api-version': '1.0',
        query: `${lat},${lon}`,
        maxFuzzyLevel: '4',
        minFuzzyLevel: '2',
        extendedPostalCodesFor: 'PAD,Addr,POI',
        'subscription-key': subscriptionKey,
      },
    });

    const geocodeResult = response.data;

    if (geocodeResult?.addresses?.length > 0) {
      const firstAddress = geocodeResult.addresses[0];
      return mapSearchResult(firstAddress, lat, lon);
    } else {
      console.warn('No addresses found for the given coordinates.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching geocode data:', error);
    throw error;
  }
};

export const phoneNumberFormat = [
  { code: '+43', name: 'Austria' },
  { code: '+32', name: 'Belgium' },
  { code: '+359', name: 'Bulgaria' },
  { code: '+385', name: 'Croatia' },
  { code: '+357', name: 'Cyprus' },
  { code: '+420', name: 'Czech Republic' },
  { code: '+45', name: 'Denmark' },
  { code: '+372', name: 'Estonia' },
  { code: '+358', name: 'Finland' },
  { code: '+33', name: 'France' },
  { code: '+49', name: 'Germany' },
  { code: '+30', name: 'Greece' },
  { code: '+36', name: 'Hungary' },
  { code: '+354', name: 'Iceland' },
  { code: '+353', name: 'Ireland' },
  { code: '+39', name: 'Italy' },
  { code: '+371', name: 'Latvia' },
  { code: '+370', name: 'Lithuania' },
  { code: '+352', name: 'Luxembourg' },
  { code: '+356', name: 'Malta' },
  { code: '+373', name: 'Moldova' },
  { code: '+377', name: 'Monaco' },
  { code: '+31', name: 'Netherlands' },
  { code: '+47', name: 'Norway' },
  { code: '+48', name: 'Poland' },
  { code: '+351', name: 'Portugal' },
  { code: '+40', name: 'Romania' },
  { code: '+7', name: 'Russia' },
  { code: '+378', name: 'San Marino' },
  { code: '+381', name: 'Serbia' },
  { code: '+421', name: 'Slovakia' },
  { code: '+386', name: 'Slovenia' },
  { code: '+34', name: 'Spain' },
  { code: '+46', name: 'Sweden' },
  { code: '+41', name: 'Switzerland' },
  { code: '+380', name: 'Ukraine' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+36', name: 'Hungary' },
  { code: '+354', name: 'Iceland' },
  { code: '+972', name: 'Israel' },
  { code: '+39', name: 'Italy' },
  { code: '+371', name: 'Latvia' },
  { code: '+370', name: 'Lithuania' },
  { code: '+352', name: 'Luxembourg' },
  { code: '+356', name: 'Malta' },
  { code: '+373', name: 'Moldova' },
  { code: '+377', name: 'Monaco' },
  { code: '+31', name: 'Netherlands' },
  { code: '+47', name: 'Norway' },
  { code: '+48', name: 'Poland' },
  { code: '+351', name: 'Portugal' },
  { code: '+40', name: 'Romania' },
  { code: '+7', name: 'Russia' },
  { code: '+378', name: 'San Marino' },
  { code: '+381', name: 'Serbia' },
  { code: '+421', name: 'Slovakia' },
  { code: '+386', name: 'Slovenia' },
  { code: '+34', name: 'Spain' },
  { code: '+46', name: 'Sweden' },
  { code: '+41', name: 'Switzerland' },
  { code: '+380', name: 'Ukraine' },
  { code: '+44', name: 'United Kingdom' },
];

import { AuthenticatedTemplate } from '@azure/msal-react';
import { Box, Card } from '@mui/material';
import { Outlet } from 'react-router-dom';
import PageHeader from '../components/typography/PageHeader';
import { useSidebar } from '../Context/SidebarContext';
import SidebarDrawer from '../components/drawer/SidebarDrawer';
import RightsidebarContent from '../components/sidebar/RightsidebarContent';
import LeftSidebarContent from '../components/sidebar/LeftSidebarContent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Authenticated = () => {
  const { isSidebarOpen, closeSidebar } = useSidebar();

  const flex = {
    display: 'flex',
    flex: '0 0 1',
  };

  return (
    <AuthenticatedTemplate>
      <Box sx={flex}>
        <Box>
          <SidebarDrawer state={true} position="left">
            <LeftSidebarContent />
          </SidebarDrawer>
        </Box>
        <Box sx={{ flex: 1, margin: '0.5% 8%' }}>
          <PageHeader />
          <Box
            component={Card}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              padding: 2,
            }}
          >
            <Outlet />
            <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} closeOnClick draggable pauseOnHover />
          </Box>
        </Box>
        {isSidebarOpen && (
          <SidebarDrawer state={isSidebarOpen} position="right" onClose={closeSidebar} width={300}>
            <RightsidebarContent />
          </SidebarDrawer>
        )}
      </Box>
    </AuthenticatedTemplate>
  );
};

export default Authenticated;
